/* Team Section */
#team {
  padding: 100px 0;
}

.section-title {
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#team .team-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

#team .img-container {
  width: 240px;
  height: 240px;
  background-color: #F5DEB3; /* Set your preferred background color here */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#team .thumbnail {
  background: transparent;
  border: 0;
  text-align: center;
}

#team .thumbnail .caption {
  padding: 10px 0 0;
  color: #888;
}
