.open-positions-container {
  font-family: 'Roboto', sans-serif;
  padding: 50px;
  margin: 100px 5% 50px 5%;
  max-width: 1200px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.042);
}

h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #343a40;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

p {
  font-size: 16px;
  color: #495057;
  margin-bottom: 40px;
  text-align: center;
}

.position-category {
  margin-bottom: 40px;
}

.position-category h3 {
  font-size: 22px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid #343a40;
  color: #343a40;
  text-transform: uppercase;
}

.position-category ul {
  list-style-type: none;
  padding: 0;
}

.position-category li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.position-category li:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.position-category li a {
  text-decoration: none;
  color: #0060c7;
  font-weight: bold;
  flex-grow: 1;
  font-size: 18px;
  transition: color 0.3s ease;
}

.position-category li a:hover {
  color: #0056b3;
}

.position-category li span {
  margin-left: 20px;
  color: #6c757d;
  font-size: 16px;
}

.position-category li span:first-of-type {
  flex-basis: 120px;
  text-align: center;
}

.position-category li span:last-of-type {
  flex-basis: 160px;
  text-align: right;
}
