.intro-text {
    position: relative;
    width: 100%;
    height: 200px; /* Adjust this based on your design */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
}

.intro-tagline {
    position: relative;
    background-color: rgba(26, 26, 26, 0.521);
    padding: 20px 50px;
    color: white;
    font-family: 'Arial', sans-serif;
    font-size: 2em;
    line-height: 1.5em;
    border-radius: 1px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: opacity 0.25s ease-in-out;
    opacity: 1;
}

.intro-tagline::before,
.intro-tagline::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 40px;
    background-color: rgba(247, 54, 54, 0.8); /* White color for the points */
}

.intro-tagline::before {
    top: 0px;
    left: -5px;
}

.intro-tagline::after {
    bottom: 0px;
    right: -5px;
}

.intro-tagline.fading {
    opacity: 0;
}